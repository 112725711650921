<!-- eslint-disable vue/no-template-shadow -->

<template>
  <div>
    <b-sidebar
      id="edit-order-item"
      ref="modal"
      right
      shadow
      width="60%"
      header-class="headerModalToggle"
      centered
      size="xl"
      :title="$t('EditOrderItem') + ' ' + articleNumber"
      hide-footer
      no-close-on-esc
      @close="onCancel"
    >
      <template #title>
        <strong style="color: #101828;">{{ `${$t('EditOrderItem')} ${articleNumber}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px; "
          class="fa fa-close"
          @click="onCancel"
        />
      </template>
      <hr>
      <div style="padding:20px">
        <div class="">
          <b-button
            v-b-toggle="`lapse-${idx}`"
            class="m-1"
            variant="light"
          >
            <b-icon-arrows-expand />
          </b-button>
          <span style="font-weight: 500">{{ $t('ShowMaterialVariant') }}</span>


          <b-collapse
            :id="`lapse-${idx}`"
            visible
          >
            <p
              v-if="duplicatedMaterials"
              style="color: red; margin: 0;"
            >
              {{ $t('DuplicateMaterial') }}
            </p>
            <div
              class="modal-card__wrapper"
              style="align-items: center"
            >

              <div
                v-for="(item, index) in form"
                :key="index"
                class="modal-card"
              >
                <b-form-group
                  id="input-group-3"
                  :label="`${$t('SelectMaterialType')}:`"
                >
                  <i
                    class="modal-card__remove"
                    @click="removeMaterialItemFromCD(index)"
                  >
                    <b-icon-trash />
                  </i>
                  <div
                    class="flex radio-form"
                    style="display:flex;justify-content: space-between;"
                  >
                    <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
                      <div class="flex aic">
                        <input
                          :id="`one${index}`"
                          v-model="item.measurementType"
                          type="radio"
                          value="Meter"
                          @click="meter(item)"
                        >
                        <label :for="`one${index}`">{{ $t('Meter') }}</label>
                      </div>
                      <div
                        :id="`one${index}`"
                        class="flex aic"
                        @click="resetPackage(item)"
                      >
                        <input
                          v-model="item.measurementType"
                          type="radio"
                          value="Package"
                        >
                        <label :for="`one${index}`">{{ $t('Package') }}</label>
                      </div>
                      <div
                        :id="`one${index}`"
                        class="flex aic"
                        @click="resetSet(item)"
                      >
                        <input
                          v-model="item.measurementType"
                          type="radio"
                          value="Set"
                        >
                        <label :for="`one${index}`">{{ $t('Set') }}</label>
                      </div>
                      <div
                        :id="`one${index}`"
                        class="flex aic"
                        @click="resetPair(item)"
                      >
                        <input
                          v-model="item.measurementType"
                          type="radio"
                          value="Pair"
                        >
                        <label :for="`one${index}`">{{ $t('Pair') }}</label>
                      </div>
                      <div
                        :id="`one${index}`"
                        class="flex aic"
                        @click="resetVerse(item)"
                      >
                        <input
                          v-model="item.measurementType"
                          type="radio"
                          value="Verse"
                        >
                        <label :for="`one${index}`">{{ $t('Beaded strand') }}</label>
                      </div>
                      <div class="flex aic">
                        <input
                          :id="`two${index}`"
                          v-model="item.measurementType"
                          type="radio"
                          value="Part"
                          @click="part(item)"
                        >
                        <label :for="`two${index}`">  {{ $t('Part') }}</label>
                      </div>
                    </div>
                    <td>
                      <img
                        v-if="item.name != null && item.name != '' "
                        v-b-tooltip="$t('ClickToViewPhoto')"
                        style="height: 30px; border-radius: 6px;cursor: pointer "
                        src="../../assets/images/imageicon.svg"
                        alt=""
                        @click.stop="getAllImages(item)"
                      >
                    </td>
                  </div>

                </b-form-group>
                <div class="search-toggle">

                  <p>{{ $t('SearchBy') }}</p>
                  <div>
                    <p
                      :class="{ active2: toggleSearch === 'MaterialName' }"
                      @click="showMaterialName(), toggleSearch = 'MaterialName'"
                    >
                      {{ $t('Name') }}
                    </p>
                    <p
                      :class="{ active2: toggleSearch === 'Description' }"
                      @click="showDescription(), toggleSearch = 'Description'"
                    >
                      {{ $t('Description') }}
                    </p>
                  </div>
                </div>
                <b-form-group
                  id="input-select-1"
                  label-for="select-1"
                >
                  <vue-select
                    v-if="item.measurementType === 'Meter' && toggleSearch === 'Description'"
                    v-model="item.name"
                    :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByMeter : getFilterVariantsByCodeLike"
                    :placeholder="$t('Description')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchMDesc"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center;"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pageM == 1"
                        @click="prevMeter"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        :style="getButtonStyle"
                        type="button"
                        :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                        @click="nextMeter"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else-if="item.measurementType === 'Meter' && toggleSearch === 'MaterialName'"
                    v-model="item.name"
                    :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByMeter : getMaterialVariantColorNameLike"
                    :placeholder="$t('MaterialVariant')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchM"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center;"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pageM == 1"
                        @click="prevMeter"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        :style="getButtonStyle"
                        type="button"
                        :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                        @click="nextMeter"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else-if="item.measurementType === 'Package' && toggleSearch === 'MaterialName'"
                    v-model="item.name"
                    :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPackage : getMaterialVariantColorNameLike"
                    :placeholder="$t('MaterialVariant')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchPack"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center;"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pagePack == 1"
                        @click="prevPackage"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        :style="getButtonStyle"
                        type="button"
                        :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                        @click="nextPackage"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else-if="item.measurementType === 'Package' && toggleSearch === 'Description'"
                    v-model="item.name"
                    :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPackage : getFilterVariantsByCodeLike"
                    :placeholder="$t('Description')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchPackDesc"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center;"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pagePack == 1"
                        @click="prevPackage"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        :style="getButtonStyle"
                        type="button"
                        :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                        @click="nextPackage"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else-if="item.measurementType === 'Set' && toggleSearch === 'MaterialName'"
                    v-model="item.name"
                    :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVBySet : getMaterialVariantColorNameLike"
                    :placeholder="$t('MaterialVariant')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchSet"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center;"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pageSet == 1"
                        @click="prevSet"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        :style="getButtonStyle"
                        type="button"
                        :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                        @click="nextSet"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else-if="item.measurementType === 'Set' && toggleSearch === 'Description'"
                    v-model="item.name"
                    :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVBySet : getFilterVariantsByCodeLike"
                    :placeholder="$t('Description')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchSetDesc"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center;"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pageSet == 1"
                        @click="prevSet"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        :style="getButtonStyle"
                        type="button"
                        :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                        @click="nextSet"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else-if="item.measurementType === 'Pair' && toggleSearch === 'MaterialName'"
                    v-model="item.name"
                    :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPair : getMaterialVariantColorNameLike"
                    :placeholder="$t('MaterialVariant')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchPair"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center;"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pagePair == 1"
                        @click="prevPair"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        :style="getButtonStyle"
                        type="button"
                        :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                        @click="nextPair"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else-if="item.measurementType === 'Pair' && toggleSearch === 'Description'"
                    v-model="item.name"
                    :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPair : getFilterVariantsByCodeLike"
                    :placeholder="$t('MaterialVariant')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchPairDesc"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center;"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pagePair == 1"
                        @click="prevPair"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        :style="getButtonStyle"
                        type="button"
                        :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                        @click="nextPair"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else-if="item.measurementType === 'Verse' && toggleSearch === 'MaterialName'"
                    v-model="item.name"
                    :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByVerse : getMaterialVariantColorNameLike"
                    :placeholder="$t('MaterialVariant')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchVerse"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center;"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pageVerse == 1"
                        @click="prevVerse"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        :style="getButtonStyle"
                        type="button"
                        :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                        @click="nextVerse"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else-if="item.measurementType === 'Verse' && toggleSearch === 'Description'"
                    v-model="item.name"
                    :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByVerse : getFilterVariantsByCodeLike"
                    :placeholder="$t('MaterialVariant')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchVerseDesc"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center;"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pageVerse == 1"
                        @click="prevVerse"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        :style="getButtonStyle"
                        type="button"
                        :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                        @click="nextVerse"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else-if="item.measurementType === 'Part' && toggleSearch === 'MaterialName'"
                    v-model="item.name"
                    :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPart : getMaterialVariantColorNameLike"
                    :placeholder="$t('MaterialVariant')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchP"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pageP == 1"
                        @click="prevPart"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        type="button"
                        :style="getButtonStylePart"
                        :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                        @click="nextPart"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                  <vue-select
                    v-else-if="item.measurementType === 'Part' && toggleSearch === 'Description'"
                    v-model="item.name"
                    :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPart : getFilterVariantsByCodeLike"
                    :placeholder="$t('MaterialVariant')"
                    :reduce="(e) => e"
                    label="materialVariantName"
                    @search="onSearchPDesc"
                    @input="currentStock(item.name.materialVariantId)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center"
                    >
                      <button
                        type="button"
                        :style="
                          pageM == 1
                            ? 'width: 50%; border: none;'
                            : 'width: 50%; border: none; background-color: #242F6E; color: white'
                        "
                        :disabled="pageP == 1"
                        @click="prevPart"
                      >
                        {{ $t('Prev') }}
                      </button>
                      <button
                        type="button"
                        :style="getButtonStylePart"
                        :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                        @click="nextPart"
                      >
                        {{ $t('Next') }}
                      </button>
                    </li>
                  </vue-select>
                </b-form-group>
                <b-form-group
                  v-if="item.name"
                  :label="`${$t('Price')}:`"
                  style="width: 100%;"
                >
                  <b-input
                    v-if="item.name.pricePerMeter"
                    v-model="item.name.pricePerMeter"
                    type="number"
                    disabled
                  />
                  <b-input
                    v-else-if="!item.name.pricePerMeter"
                    v-model="item.pricePerMeter"
                    type="number"
                    disabled
                  />
                </b-form-group>
                <b-form-group
                  v-if="item.name"
                  :label="`${$t('PriceDiscount')}:`"
                  style="width: 100%;"
                >
                  <b-input
                    v-model="item.pricePerMeterWithDiscount"
                    type="number"
                  />
                </b-form-group>


                <b-form-group
                  v-if="item.measurementType === 'Meter'"
                  :label="`${$t('Meter')}:`"
                >
                  <b-input
                    v-model="item.length"
                    type="number"
                    disabled
                  />
                </b-form-group>
                <b-form-group
                  v-else-if="item.measurementType === 'Package'"
                  :label="`${$t('Package')}:`"
                  style="width: 100%;"
                >
                  <b-input
                    v-model="item.length"
                    type="number"
                    disabled
                  />
                </b-form-group>
                <b-form-group
                  v-else-if="item.measurementType === 'Set'"
                  :label="`${$t('Set')}:`"
                  style="width: 100%;"
                >
                  <b-input
                    v-model="item.length"
                    type="number"
                    disabled
                  />
                </b-form-group>
                <b-form-group
                  v-else-if="item.measurementType === 'Pair'"
                  :label="`${$t('Pair')}:`"
                  style="width: 100%;"
                >
                  <b-input
                    v-model="item.length"
                    type="number"
                    disabled
                  />
                </b-form-group>
                <b-form-group
                  v-else-if="item.measurementType === 'Verse'"
                  :label="`${$t('Verse')}:`"
                  style="width: 100%;"
                >
                  <b-input
                    v-model="item.length"
                    type="number"
                    disabled
                  />
                </b-form-group>
                <b-form-group
                  v-else
                  :label="`${$t('Part')}:`"
                >
                  <b-input
                    v-model="item.quantity"
                    type="number"
                    disabled
                  />
                </b-form-group>
                <b-form-group
                  v-if="item.measurementType == 'Part'"
                  :label="`${$t('New quantity')}:`"
                >
                  <b-input
                    v-model="item.newQuantity"
                    type="number"
                    @input="valid(item.newQuantity,index)"
                  />
                  <p
                    v-if="item.newQuantity < 0.0001 "
                    style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                  >
                    {{ $t('TheValue') }}
                  </p>
                </b-form-group>
                <b-form-group
                  v-else
                  :label="`${$t('New length')}:`"
                >
                  <b-input
                    v-model="item.newLength"
                    type="number"
                    @input="valid(item.newLength,index)"
                  />
                  <p
                    v-if="item.newLength < 0.0001 "
                    style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                  >
                    {{ $t('TheValue') }}
                  </p>
                </b-form-group>

                <b-form-group
                  v-if="item.name ? item.name.materialVariantId === clickedMaterialVariantId : false"
                  :label="`${$t('Stock')}:`"
                  style="width: 100%;"
                >
                  <b-form-input
                    v-model="getSavedStock.stockValue"
                    type="number"
                    disabled
                  />
                </b-form-group>
              </div>
              <button
                type="button"
                size="sm"
                class="button"
                style="margin: 20px 0;"
                @click="addNewRowForCD()"
              >
                <p style="margin: 0">
                  + {{ $t('AddMaterial') }}
                </p>
              </button>
            </div>
          </b-collapse>
          <p
            v-if="emptyList"
            style="color: red; margin: 0;"
          >
            {{ $t('EmptyMaterials') }}
          </p>
        </div>
      </div>
      <div class="buttonsEverywhereToggle">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
      <vue-easy-lightbox
        :esc-disabled="false"
        :visible="image.visible"
        :imgs="image.imgs"
        :index="image.index"
        @hide="handleHide"
      />
    </b-sidebar>

  </div>
</template>

<script>
// import flatPickr from 'vue-flatpickr-component';
import { mapGetters, mapActions } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox'
import { client } from '../../domainConfig'



export default {
  components: {
    // flatPickr,
    VueEasyLightbox,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['articleNumber'],
  data() {
    return {
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
      toggleSearch: 'MaterialName',
      selectedMaterialVariantPackage: '',
      selectedMaterialVariant: '',
      selectedMaterialVariantPart: '',
      selectedMaterialVariantSet: '',
      selectedMaterialVariantPair: '',
      selectedMaterialVariantVerse: '',
      clickedMaterialVariantId: null,
      client: client.clientName,
      showLengthError: false,
      formm: {
        orderItemId: null,
        bodyMeasurements: {
          thorax: null,
          aroundBust: null,
          aroundUnderBust: null,
          waist: null,
          aroundHips: null,
          aroundLeg: null,
          aroundKnee: null,
          aroundNeck: null,
          aroundSleeve: null,
          ankle: null,
          breastReduction: null,
          chestDistance: null,
          interBust: null,
          frontBody: null,
          length1: null,
          length2: null,
          length3: null,
          length4: null,
          elbow: null,
          wrist: null,
          back: null,
          backBody: null,
          shoulders: null,
          declineOfTheHips: null,
          sleeveLength: null,
        },
        articleNumber: '',
        size: '',
        pickUpDate: '',
        price: 0,
        paidAmount: 0,
        materialVariantInStoreResponseDtos: [],
      },
      isUndefined: false,
      expand: false,
      index: 0,
      pageM: 1,
      pageP: 1,
      pagePack: 1,
      pageSet: 1,
      pagePair: 1,
      pageVerse: 1,
      pageSize: 15,
      radio: null,
      idx: 0,
      showBody: true,
      form: [],
      duplicatedMaterials: false,
      emptyList: false,
    }
  },
  computed: {
    ...mapGetters(['getImagesProduct', 'getSavedStock', 'getMaterialsVByPackage', 'getMaterialsVBySet', 'getMaterialsVByVerse', 'getMaterialsVByPair', 'getTotalItemsPages', 'getMaterialVariantColorNameLike', 'getEditOrder', 'getEditOrderItemMaterialVariant', 'getCurrentPageForMVP', 'getMaterialsVByMeter',
      'getMaterialsVByPart', 'getFilteredMV', 'getTotalPagesForMVP',
      'getTotalPagesForMVM', 'getCurrentPageForMVM', 'getCurrentPageForMVP',
      'getMeasermentsById', 'getFilterVariantsByCodeLike']),
    getButtonStyle() {
      if (this.selectedMaterialVariant !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVM
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    getButtonStylePart() {
      if (this.selectedMaterialVariantPart !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVP
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
  },
  watch: {
    form: {
      handler(newValue) {
        const ids = newValue.map((item) => { return item.name.materialVariantName ? item.name.materialVariantName || item.name : item.name });
        const uniqueIds = new Set(ids);
        this.duplicatedMaterials = ids.length !== uniqueIds.size;
        this.emptyList = newValue.length === 0;
      },
      deep: true,
    },
    getEditOrderItemMaterialVariant() {
      this.fillForm()
    },
  },
  mounted() {
    // console.log('articlee', this.getEditOrder.articleNumber)
    // this.formm.bodyMeasurements = this.getEditOrder.bodyMeasurements;
    // this.formm.articleNumber = this.getEditOrder.articleNumber
    // this.formm.materialVariantInStoreResponseDtos = this.getEditOrderItemMaterialVariant

    // this.getEditOrderItemMaterialVariant.materialVariantId = this.getEditOrderItemMaterialVariant.name.materialVariantId
    // this.getEditOrderItemMaterialVariant.name = this.getEditOrderItemMaterialVariant.name.materialVariantName
  },
  methods: {
    ...mapActions(['getImagesByMaterialVariantId', 'currentStockByVariant',
      'resetAllFiltered', 'filterVariantByColor', 'getMaterialVariantsToMDCPagination',
      'edit_OrderItem', 'getMaterialVariantsToMDCPagination', 'testResetMV',
      'loadMaterialVaraintsCategoryNameLike', 'filterVariantByCode']),
    addNewRowForCD() {
      this.form.push({
        isEditable: true,
        isStockAvailable: true,
        length: 0,
        materialVariantId: null,
        measurementType: 'Meter',
        name: null,
        quantity: 0,
        newLength: 0,
        newQuantity: 0,
        pricePerMeterWithDiscount: null,
      });
    },
    showMaterialName() {
      this.toggleSearch = 'MaterialName'
    },
    showDescription() {
      this.toggleSearch = 'Description'
    },
    currentStock(value) {
      this.clickedMaterialVariantId = value
      this.currentStockByVariant(value)
    },
    async getAllImages(item) {
      try {
        let materialVariantId = null;
        if (item && item.materialVariantId != null) {
          materialVariantId = item.materialVariantId;
        } else if (item && item.name != null && item.name.materialVariantId != null) {
          materialVariantId = item.name.materialVariantId;
        }
        if (materialVariantId !== null) {
          await this.getImagesByMaterialVariantId(materialVariantId).then((res) => {
            console.log(res);
          });
          this.image.imgs = [];
          for (let i = 0; i < this.getImagesProduct.length; i++) {
            const image = this.getImagesProduct[i];
            this.image.imgs.push({
              src: image.url,
              title: image.fileName,
              materialVariantPhotoId: image.materialVariantPhotoId,
              fileName: image.fileName,
              fileType: image.fileType,
            });
          }
          this.show();
        } else {
          console.log('Material Variant ID is null or undefined.');
        }
      } catch (e) {
        console.log(e);
      }
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    valid(item) {
      if (item < 0.001) {
        this.showLengthError = true;
      } else {
        this.showLengthError = false;
      }
    },
    fillForm() {
      this.form = this.getEditOrderItemMaterialVariant.map((item) => {
        return {
          ...item,
          newQuantity: item.quantity,
          newLength: item.length,
        }
      });
    },
    removeMaterialItemFromCD(index) {
      this.form.splice(index, 1);
    },
    meter(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      value.newQuantity = 0
      value.newLength = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
    },
    resetPackage(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      value.newQuantity = 0
      value.newLength = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
    },
    resetSet(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      value.newQuantity = 0
      value.newLength = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
    },
    resetPair(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      value.newQuantity = 0
      value.newLength = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
    },
    resetVerse(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      value.newQuantity = 0
      value.newLength = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
    },
    part(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      value.newQuantity = 0
      value.newLength = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    async onSubmit() {
      if (this.duplicatedMaterials) {
        return;
      }
      if (this.emptyList) {
        return;
      }
      try {
        const normalizedForm = this.form.map((item) => {
          if (typeof item.name === 'object') {
            return {
              ...item,
              materialVariantId: item.name.materialVariantId || item.materialVariantId,
              materialVariantName: item.name.materialVariantName,
              pricePerMeter: item.name.pricePerMeter,
              name: item.name.materialVariantName,
            };
          }
          return item;
        });

        this.$emit('editOrderItemm', normalizedForm);
        this.onCancel();
      } catch (error) {
        console.log(error)
      }
    },
    removeProbeDate(index) {
      this.dress.probeDates.splice(index, 1);
    },
    addNewDate() {
      this.dress.probeDates.push(
        {
          probeName: `${this.$t('FitDate')} ${this.index += 1}`,
          dateOfProbe: '',
          notes: null,
        },
      );
    },
    // updateSelectedValue() {
    //   this.$emit('selected-value-change', this.idx, this.selectedValue);
    // },
    removeCustomDress(index) {
      this.$emit('sendIndex', index)
    },
    async getMVbyMeter(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Meter',
      });
    },
    async getMVbyPackage(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Package',
      });
    },
    async getMVbySet(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Set',
      });
    },
    async getMVbyVerse(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Verse',
      });
    },
    async getMVbyPair(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Pair',
      });
    },
    async getMVbyPart(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Part',
      });
    },
    nextMeter() {
      if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant == '') {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant != '') {
        this.pageM += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    prevMeter() {
      if (this.pageM > 1 && this.selectedMaterialVariant == '') {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM > 1 && this.selectedMaterialVariant != '') {
        this.pageM -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    nextPackage() {
      if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage == '') {
        this.pagePackage += 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage != '') {
        this.pagePackage += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    prevPackage() {
      if (this.pagePackage > 1 && this.selectedMaterialVariantPackage == '') {
        this.pagePackage -= 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage > 1 && this.selectedMaterialVariantPackage != '') {
        this.pagePackage -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    nextSet() {
      if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet == '') {
        this.pageSet += 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet != '') {
        this.pageSet += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    prevSet() {
      if (this.pageSet > 1 && this.selectedMaterialVariantSet == '') {
        this.pageSet -= 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet > 1 && this.selectedMaterialVariantSet != '') {
        this.pageSet -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    nextVerse() {
      if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse == '') {
        this.pageVerse += 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse != '') {
        this.pageVerse += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    prevVerse() {
      if (this.pageVerse > 1 && this.selectedMaterialVariantVerse == '') {
        this.pageVerse -= 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse > 1 && this.selectedMaterialVariantVerse != '') {
        this.pageVerse -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    nextPair() {
      if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair == '') {
        this.pagePair += 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair != '') {
        this.pagePair += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    prevPair() {
      if (this.pagePair > 1 && this.selectedMaterialVariantPair == '') {
        this.pagePair -= 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair > 1 && this.selectedMaterialVariantPair != '') {
        this.pagePair -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    nextPart() {
      if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart == '') {
        this.pageP += 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart != '') {
        this.pageP += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    prevPart() {
      if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    async onSearchM(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariant = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchMDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariant = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPack(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPackDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchSet(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchSetDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPair(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPairDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchVerse(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchVerseDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          })
        }
      }, 500);
    },

    async onSearchP(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchPDesc(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          })
        }
      }, 500);
    },
    onCancel() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        // this.resetForm();
        this.form = []
        this.formm = {}
        this.materials = [];
      }, 10);
    },
  },

};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.active2 {
  font-weight: bold;
  color: $base-color;
}
.search-toggle {
  margin: 5px 0px;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.headline {
  padding: 5px 10px;
  background-color: $base-color;
  color: $digit-white;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}
.front-body {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 20px;
  img {
    width: 100%;
  }
  .betweenCol{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .input{
    max-width: 100%;
    height: 51px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .toraks {
    display: flex;
    position: absolute;
    top: 106px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 165px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .beli {
    display: flex;
    position: absolute;
    top: 200px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 148px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 38px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 85px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 128px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 222px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 127px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 68px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
.btn-con {
  display: flex;
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 126px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .brryli {
    display: flex;
    position: absolute;
    top: 156px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 60px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 202px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .supet {
    display: flex;
    position: absolute;
    top: 97px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 95px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
@media only screen and (max-width: 1200px){
  .front-body {
  position: relative;
  .toraks {
    top: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjoksit {
    position: absolute;
    top: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiNenGjoksit {
    position: absolute;
    top: 125px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .beli {
    position: absolute;
    top: 155px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .vithet {
    position: absolute;
    top:249px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiKembes {
    position: absolute;
    bottom: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjurit {
    position: absolute;
    bottom: 35px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .zoguKembeve {
    position: absolute;
    bottom: 5px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaGjoksit {
    position: absolute;
    top: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .nderGjoks {
    position: absolute;
    top: 125px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPara {
    position: absolute;
    top: 155px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .distancaGjoksit {
    position: absolute;
    top: 95px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia1 {
    position: absolute;
    bottom: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia2 {
    position: absolute;
    bottom: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia3 {
    position: absolute;
    bottom: 35px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia4 {
    position: absolute;
    bottom: 5px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
.btn-con {
  display: flex;
  button {
    border: none;
    background-color: #6c757d;;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    position: absolute;
    top: 126px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .brryli {
    position: absolute;
    top: 156px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .kyçi {
    position: absolute;
    top: 192px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiQafes {
    position: absolute;
    top: 70px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPrapa {
    position: absolute;
    top: 215px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .supet {
    position: absolute;
    top: 97px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .shpina {
    position: absolute;
    top: 70px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaVitheve {
    position: absolute;
    bottom: 100px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesiaMenges {
    position: absolute;
    top: 98px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
}
input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: $base-color ;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
@media only screen and (max-width: 800px) {
  .front-body {
    .vithet {
      position: absolute;
    top: 195px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
    }
  }
}

</style>
